import * as React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import 'twin.macro';

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Section, SectionHeader } from "../components/section";
import PostCard from "../components/postCard";

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Section>
        <SectionHeader>Latest Blog</SectionHeader>
        <ol tw="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
          {posts.map(post => {
            return (
              <li key={post.fields.slug}>
                <PostCard post={post} />
              </li>
            )
          })}
        </ol>
        <div tw="mt-16 text-center">
          <Link to="/articles" tw="inline-block py-2 px-8 text-sm border light:border-gray-400 dark:border-gray-600 tracking-wider rounded-full">MORE</Link>
        </div>
      </Section>
    </Layout>
  )
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit:6, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date
          title
          description
          eyecatch {
            childImageSharp {
              gatsbyImageData (
                placeholder: DOMINANT_COLOR
                aspectRatio: 1.618
              )
            }
          }
          categories
          tags
        }
      }
    }
  }
`;
