import React from "react";
import { Link } from "gatsby";
import { DateTime } from "luxon";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { css } from 'twin.macro';

const PostCard = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug;
  const image = getImage(post.frontmatter.eyecatch);

  return (
    <article className="group" tw="flex flex-col space-y-4">
      <header tw="">
        <div tw="mb-6 rounded-md">
        { image && <GatsbyImage image={image} tw="rounded-md" alt={post.frontmatter.title} loading="lazy"  /> }
        { !image && <div tw="light:bg-gray-300 dark:bg-gray-700 rounded-md" css={css` padding-bottom: 61.8%; `} /> }
        </div>

        <h2 tw="text-lg font-bold light:group-hover:text-gray-900 dark:group-hover:text-gray-100">
          <Link tw="hover:underline" to={`/articles${post.fields.slug}`}>
            {title}
          </Link>
        </h2>
        <time tw="text-sm light:text-gray-700 dark:text-gray-400" dateTime={post.frontmatter.date}>
          {DateTime.fromISO(post.frontmatter.date).toFormat('yyyy.MM.dd')}
        </time>
      </header>
      <section>
        <p tw="text-sm light:text-gray-600 dark:text-gray-500"
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.description || post.excerpt,
          }}
        />
      </section>
    </article>
  );
}

export default PostCard;
