import React from "react";
import 'twin.macro';

export const Section = (props) => {
  return (
    <section tw="container mx-auto px-8" {...props}>
      {props.children}
    </section>
  );
}

export const SectionHeader = (props) => {
  return (
    <h1 tw="mt-0 mb-8 text-xl tracking-wider" {...props}>
      {props.children}
    </h1>
  );
}
